body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  user-select: none;
  -webkit-user-drag: none;
}

@font-face {
  font-family: 'Starion';
  src: local('Starion'), url(./fonts/Starion.otf) format('opentype');
}

@font-face {
  font-family: 'ZStray';
  src: local('ZStray'), url(./fonts/ZStray.otf) format('opentype');
}

@font-face {
  font-family: 'Lexa';
  src: local('Lexa'), url(./fonts/Lexa.otf) format('opentype');
}

@font-face {
  font-family: 'Raumdeuter';
  src: local('Raumdeuter'), url(./fonts/Raumdeuter.otf) format('opentype');
}

@font-face {
  font-family: 'Galaxia';
  src: local('Galaxia'), url(./fonts/Galaxia.otf) format('opentype');
}

@font-face {
  font-family: 'Plaztma';
  src: local('Plaztma'), url(./fonts/Plaztma.otf) format('opentype');
}

@font-face {
  font-family: 'Leentank';
  src: local('Leentank'), url(./fonts/Leentank.otf) format('opentype');
}

@font-face {
  font-family: 'Kleemax';
  src: local('Kleemax'), url(./fonts/Kleemax.otf) format('opentype');
}

@font-face {
  font-family: 'RAYDIS';
  src: local('RAYDIS'), url(./fonts/RAYDIS.otf) format('opentype');
}

@font-face {
  font-family: 'Techla';
  src: local('Techla'), url(./fonts/Techla.otf) format('opentype');
}

@font-face {
  font-family: 'Kosmos';
  src: local('Kosmos'), url(./fonts/Kosmos.otf) format('opentype');
}

@font-face {
  font-family: 'CMYK';
  src: local('CMYK'), url(./fonts/CMYK.otf) format('opentype');
}

@font-face {
  font-family: 'Nathaly-Couple';
  src: local('Nathaly-Couple'), url(./fonts/Nathaly-Couple.otf) format('opentype');
}

@font-face {
  font-family: 'NIMAsolid-Black';
  src: local('NIMAsolid-Black'), url(./fonts/NIMAsolid-Black.otf) format('opentype');
}

@font-face {
  font-family: 'jxtabe-regular';
  src: local('jxtabe-regular'), url(./fonts/jxtabe-regular.ttf) format('opentype');
}

@font-face {
  font-family: 'jxtabe-thin';
  src: local('jxtabe-thin'), url(./fonts/jxtabe-thin.ttf) format('opentype');
}

@font-face {
  font-family: 'Cola';
  src: local('Cola'), url(./fonts/Cola.otf) format('opentype');
}

@font-face {
  font-family: 'NB Architekt Pro';
  src: local('NB Architekt Pro'), url('./fonts/NB Architekt Pro.otf') format('opentype');
}

@font-face {
  font-family: 'NBArchitektPro-Bold';
  src: local('NBArchitektPro-Bold'), url('./fonts/NBArchitektPro-Bold.woff2') format('opentype');
}

@font-face {
  font-family: 'NBArchitektPro-Light';
  src: local('NBArchitektPro-Light'), url('./fonts/NBArchitektPro-Light.woff2') format('opentype');
}

@font-face {
  font-family: 'NBArchitektPro-Regular';
  src: local('NBArchitektPro-Regular'), url('./fonts/NBArchitektPro-Regular.woff2') format('opentype');
}